export const environment = {
    production: false,
    env: {
        name:"QA",
        banner: "QA Environment"
    },
    apiServer:  {
        webSocketlUrl: "wss://portalserver-qa.acimobills.com/portal",
        portalUrl: "https://portalserver-qa.acimobills.com/portal/api",
        authServerUrl: "https://portalserver-qa.acimobills.com/auth/api",
        rtpnUrl: "https://qa.walletron.com/rtpn/api"         
    },
    appSetting: {
        baseAuth: "Basic YWRtaW46d2FsbGV0cm9uZ2Y=",
        portalAuth: "Basic dXNyX21vbml0b3I6THl6S1FXN1JlVlBoSDJCMw==",
        communicationAuth: "Basic dXNyX2NvbW1fbW5ncjpMQ3BDR21WZzQ3dFdnTFJQ"
    },
    brandMigration: {
        sourceRegions: ["uat", "demo", "client2prod", "mbprod", "mobills2prod", "wuprod"],
        targetRegions: ["qa"]
    }
};